<template>
  <lab-task><lab-calorimetry-specific-heat-capacity-of-metal /></lab-task>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabCalorimetrySpecificHeatCapacityOfMetal from '@/tasks/components/simulations/LabCalorimetrySpecificHeatCapacityOfMetal';
import LabTask from './labs/LabTask';
export default defineComponent({
  name: 'CalorimetrySpecificHeatCapacityOfMetal',
  components: {LabCalorimetrySpecificHeatCapacityOfMetal, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
